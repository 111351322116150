import React from "react"
import { Routes } from "../../data/routes"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "Legal",
  description: "Yup Terms of Service",
}

const Legal = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.legal}
      />
      <div className="p-5 max-w-7xl mx-auto">
        <div className="pb-5 border-b border-gray-200">
          <h1 className="text-xl leading-6 font-medium text-gray-900">
            Terms of Service
          </h1>
        </div>
        <p className="font-bold mt-4">
          We offer you the services of Yup Technologies Inc. ("Yup") on the
          condition that you agree to the following terms:
        </p>
        <h2 className="text-lg font-bold mt-4">Acceptance</h2>
        <p>
          The following legal terms and conditions govern your use of the
          problem solutions, steps, explanations, information, graphs,
          worksheets, materials and documents being made available to you on
          this website and app (the "Materials") by Yup. BY USING THE MATERIALS,
          YOU SIGNIFY ELECTRONICALLY YOUR AGREEMENT TO ALL TERMS, CONDITIONS,
          AND NOTICES CONTAINED OR REFERENCED HEREIN (THE "TERMS AND
          CONDITIONS"). Please review these terms carefully. If you do not agree
          to these Terms and Conditions, please do not use this website or the
          Materials. We reserve the right, at our discretion, to update or
          revise these Terms and Conditions at any time. Please check the Terms
          and Conditions periodically for changes. Your continued use of the
          Materials following the posting of any changes to the Terms and
          Conditions constitutes acceptance of those changes.
        </p>
        <h2 className="text-lg mt-4 font-bold">Proprietary Rights</h2>
        <p>
          You acknowledge and agree that the Materials are protected by
          copyright, trademark, patent, trade secret, and other proprietary
          rights and laws. No portion of the Materials or the data contained in
          the Materials may be reproduced in whole or in part unless expressly
          authorized by Yup in writing. The Yup name and logo, and all related
          product and service names, design marks and slogans are the trademarks
          or registered trademarks of Yup. All other product and service marks
          contained herein are the trademarks of their respective owners. You
          may not use any Yup or third party trademarks or logos without the
          prior written consent of Yup or the applicable trademark owner.
        </p>
        <h2 className="text-lg mt-4 font-bold">
          License to and Limitations on Use of Materials
        </h2>
        <p>
          Provided you comply with these Terms and Conditions, Yup grants you an
          exclusive, non-transferable license to view and print the Materials
          solely for your own personal non-commercial use. You may not share
          materials with another individual for that individual's personal
          non-commercial use. You may not commercially exploit the Materials or
          the underlying data, including without limitation, you may not create
          derivative works of the Materials, use any data mining, robots, or
          similar data gathering and extraction tools on the Materials, frame
          any portion of the Materials, or reprint, copy, modify, translate,
          port, publish, sublicense, assign, transfer, sell, or otherwise
          distribute the Materials without the prior written consent of Yup. You
          shall not derive or attempt to derive the source code or structure of
          all or any portion of the Materials by reverse engineering,
          disassembly, decompilation or any other means. You shall use the
          Materials and this website for legal purposes only and shall not use
          them in any manner that violates the laws of any jurisdiction. You do
          not receive any, and Yup and its licensors retain all ownership rights
          in the Materials. You may not alter or remove any copyright notice or
          proprietary legend contained in or on the Materials. Nothing contained
          herein shall be construed as granting you a license under any
          copyright, trademark, patent or other intellectual property right of
          Yup or any third party, except for the right of use license expressly
          set forth herein.
        </p>
        <h2 className="text-lg mt-4 font-bold">Your License to Yup</h2>
        <p>
          Should you provide Yup with information including but not limited to
          feedback, data, answers, questions, comments, suggestions, plans,
          ideas or the like, such information shall be deemed to be non
          confidential and Yup assumes no obligation to protect such information
          from disclosure. The submission of such information to Yup shall in no
          way prevent the purchase, manufacture or use of similar products,
          services, plans and ideas by Yup for any purpose whatsoever and by
          such submission you (i) grant to Yup the irrevocable right to
          reproduce, use, disclose and distribute the information to others
          without restriction or compensation required, and (ii) warrant and
          represent that you own or otherwise control all of the rights to the
          information and that public posting and use of the information by Yup
          will not infringe or violate the rights of any third party.
        </p>
        <h2 className="text-lg mt-4 font-bold">No Warranties</h2>
        <p>
          THIS WEBSITE AND THE MATERIALS ARE PROVIDED WITH ALL FAULTS ON AN "AS
          IS" AND "AS AVAILABLE" BASIS. YUP, ITS LICENSORS AND OTHER SUPPLIERS
          DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE
          WARRANTIES THAT THE WEBSITE AND MATERIALS ARE FREE OF DEFECTS, VIRUS
          FREE, AND ABLE TO OPERATE ON AN UNINTERRUPTED BASIS, THAT THEY WILL
          MEET YOUR REQUIREMENTS, OR THAT ERRORS WILL BE CORRECTED, AND THE
          IMPLIED WARRANTIES THAT THE WEBSITE AND MATERIALS ARE MERCHANTABLE, OF
          SATISFACTORY QUALITY, ACCURATE, FIT FOR A PARTICULAR PURPOSE OR NEED,
          OR NON-INFRINGING, UNLESS SUCH IMPLIED WARRANTIES ARE LEGALLY
          INCAPABLE OF EXCLUSION. NO ADVICE OR INFORMATION GIVEN BY YUP, ITS
          AFFILIATES OR THEIR RESPECTIVE EMPLOYEES OR AUTHORIZED AGENTS SHALL
          CREATE A WARRANTY OR EXPAND THE SCOPE OF ANY WARRANTY THAT CANNOT BE
          DISCLAIMED UNDER APPLICABLE LAW. YOUR USE OF THIS WEBSITE AND THE
          MATERIALS IS SOLELY AT YOUR OWN RISK. SOME STATES OR OTHER
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE
          ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS
          THAT VARY FROM STATE TO STATE AND JURISDICTION TO JURISDICTION. Your
          use of the website and Materials is at YOUR OWN RISK and Yup is under
          no obligation to provide you with any support, error corrections,
          updates, upgrades, bug fixes and/or enhancements of the website and
          Materials. Yup has the right at any time to change, modify, add to or
          discontinue or retire any aspect or feature of the website or
          Materials, including, but not limited to, the software, content, hours
          of availability, equipment needed for access or use, or the
          availability of the website or Materials on any particular device or
          communications service. Yup has no obligation to provide you with
          notice of any such changes.
        </p>
        <h2 className="text-lg mt-4 font-bold">Limitation of Liability</h2>
        <p>
          UNDER NO CIRCUMSTANCES SHALL YUP, OR ANY OF YUP'S AFFILIATES,
          EMPLOYEES, DISTRIBUTORS, SUPPLIERS, LICENSORS, AGENTS OR RESELLERS
          (COLLECTIVELY, THE "YUP GROUP") BE LIABLE FOR ANY INDIRECT, SPECIAL,
          INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES THAT RESULT FROM THE
          USE OF, MISUSE OF, INABILITY TO USE, OR RELIANCE UPON THIS WEBSITE OR
          THE MATERIALS. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY
          IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER
          BASIS, EVEN IF THE YUP GROUP HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGE. SUCH LIMITATION SHALL APPLY NOTWITHSTANDING ANY FAILURE
          OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT
          PERMITTED BY LAW. SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE
          EXCLUSION OR LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL
          DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
          THE YUP GROUPS LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE
          MAXIMUM EXTENT PERMITTED BY LAW. ADDITIONALLY, UNDER NO CIRCUMSTANCES
          SHALL THE YUP GROUP BE HELD RESPONSIBLE OR LIABLE, DIRECTLY OR
          INDIRECTLY, FOR ANY LOSS OR DAMAGE CAUSED OR ALLEGED TO HAVE BEEN
          CAUSED TO YOU IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY
          CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SITES LINKED TO
          OR FROM THIS WEBSITE. Without limiting the foregoing, under no
          circumstances shall the Yup Group be held liable for any delay or
          failure in performance resulting directly or indirectly from acts of
          nature, forces, or causes beyond its reasonable control, including,
          without limitation, Internet failures, computer equipment failures,
          telecommunication equipment failures, other equipment failures,
          electrical power failures, strikes, labor disputes, riots,
          insurrections, civil disturbances, shortages of labor or materials,
          fires, floods, storms, explosions, acts of God, war, governmental
          actions, orders of domestic or foreign courts or tribunals, or
          non-performance of third parties.
        </p>
        <h2 className="text-lg mt-4 font-bold">Indemnification</h2>
        <p>
          You agree to indemnify, hold harmless and (upon Yup's request) defend
          the Yup Group from all liabilities, claims, and expenses, including
          attorney's fees that arise from your violation of these Terms and
          Conditions or your use or misuse of the Materials or this website. Yup
          reserves the right, at its own expense, to assume the exclusive
          defense and control of any matter otherwise subject to indemnification
          by you, in which event you will cooperate with Yup in asserting any
          available defenses.
        </p>
        <h2 className="text-lg mt-4 font-bold">Disclaimer of Endorsement</h2>
        <p>
          Reference herein or on the website to any products, services,
          processes, hypertext links to third parties or other information by
          trade name, trademark, manufacturer, supplier or otherwise does not
          constitute or imply its endorsement, sponsorship or recommendation by
          Yup. Product and service information are the sole responsibility of
          each individual vendor.
        </p>
        <h2 className="text-lg mt-4 font-bold">International Use</h2>
        <p>
          Yup makes no representation that the Materials are appropriate or
          available for use in locations outside the United States, and
          accessing it from territories where the Materials are illegal is
          prohibited. Those who choose to access this website from other
          locations do so on their own initiative and are responsible for
          compliance with local laws.
        </p>
        <h2 className="text-lg mt-4 font-bold">Choice of Law and Forum</h2>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of the state of California, excluding its
          conflicts of law rules. You expressly agree that the exclusive
          jurisdiction for any claim or action arising out of or relating to
          these Terms and Conditions or your use of the Materials and this
          website shall be initiated and maintained only in the state and
          federal courts located in San Francisco, California, and you further
          agree and submit to the exercise of personal jurisdiction and venue of
          such courts for the purpose of litigating any such claim or action.
          Any action by you against Yup or the Yup Group hereunder shall be
          brought within one (1) year after the claim arose, or be barred.
          Severability and Integration: These Terms and Conditions constitute
          the entire agreement between you and Yup with respect to the Materials
          and this website. If any part of these Terms and Conditions is held
          invalid or unenforceable, that portion shall be construed in a manner
          consistent with applicable law to reflect, as nearly as possible, the
          original intentions of the parties, and the remaining portions shall
          remain in full force and effect. Termination Yup reserves the right,
          in its sole discretion, to terminate your access to the Materials or
          to all or part of this website, with or without notice. For further
          information and/or questions regarding the Materials or this website,
          please contact us at{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>
          .
        </p>
        <h2 className="text-lg mt-4 font-bold">Student Privacy</h2>
        <p>
          When Yup is used by a School or authorized school officials for an
          educational purpose, Yup may collect or have access to Student Data
          that is provided by the School or by the Student. "Student Data" is
          personal information that is directly related to an identifiable
          Student and may include "educational records" as defined by the Family
          Educational Rights and Privacy Act ("FERPA"), 20 U.S.C. § 1232(g).
        </p>
        <h2 className="text-lg mt-4 font-bold">Confidentiality</h2>
        <p>
          Yup agrees to treat all personal information related to delivering
          school services as confidential and not to share it with third parties
          other than as described in these Terms and in our Privacy Policy.
        </p>
        <h2 className="text-lg mt-4 font-bold">Student Data Access</h2>
        <p>
          You agree you are authorized to grant Yup access or to collect Student
          Data for the purpose of providing the Service. When operating on
          behalf of a school in the U.S., Yup shall collect and process Student
          Data as a School Official with a legitimate educational interest
          pursuant to FERPA 34 CFR Part 99.31(a)(1). As between the parties, the
          School or the Student owns and controls the Student Data. Yup does not
          own or control, or license such Student Data, except as to provide the
          Service and as described in these Terms.
        </p>
        <h2 className="text-lg mt-4 font-bold">
          Personal Information and Student Data Consents and Authority.
        </h2>
        <p>
          If you receive access to Yup through your school ("School User"), you
          represent and warrant that you have provided appropriate disclosures
          to your School and to parents regarding your sharing such Personal
          Information with Yup. Both Parties agree to uphold their obligations
          under the Family Educational Rights and Privacy Act ("FERPA"), the
          Protection of Pupil Rights Amendment ("PPRA"), and the Children's
          Online Privacy and Protection Act ("COPPA") and applicable State laws
          relating to student data privacy. Yup relies on each School to obtain
          and provide appropriate consent and disclosures, if necessary, for Yup
          to collect any Student Data, including the collection of Student Data
          directly from students under 13, as permitted under COPPA. You agree
          to comply with these Terms and all laws and regulations governing the
          protection of personal information, including children's information,
          and the sharing of student education records and to notify Yup of any
          additional requirements relating to your specific jurisdiction.
        </p>
        <h2 className="text-lg mt-4 font-bold">Use of Student Data</h2>
        <p>
          By submitting or providing us access to Student Data, you agree that
          Yup may use the Student Data solely for the purposes of (i) providing
          the Service, (ii) improving and developing our Service, (iii)
          enforcing our rights under these Terms, and (iv) as permitted with the
          School's or the User's consent. Yup shall not sell student data or use
          Student Data to engage in targeted advertising.
        </p>
        <h2 className="text-lg mt-4 font-bold">
          Student Data Retention and Deletion Requests
        </h2>
        <p>
          Schools may request that we delete Student Data in our possession at
          any time by providing such a request in writing, except that Yup shall
          not be required to delete content a Student shared to public areas of
          the Service. We shall respond to the deletion request as soon as
          possible, but in most instances within 45 days, other than for data
          stored on backup tapes which shall be deleted in the ordinary course
          of business. We are not required to delete data that has been derived
          from Student Data so long as it has been anonymized such that it does
          not reasonably identify an individual. Similarly, we are not required
          to delete information which a Student or parent has saved or
          transferred to a consumer plan.
        </p>
        <h2 className="text-lg mt-4 font-bold">Data Breach Notification</h2>
        <p>
          We have implemented administrative, physical and technical safeguards
          designed to secure Personal Information, including Student Data, from
          unauthorized access, disclosure and use. In the event we have a
          reasonable, good faith belief that an unauthorized party has gained
          access to or been disclosed Student Data (a "Security Event"), that we
          have collected or received through the Service, we will promptly
          notify the School. If, due to a Security Event which is caused by the
          acts or omissions of Yup or its agents, a notification to an
          individual, organization or government agency is required under
          applicable privacy laws, the School shall be responsible for the
          timing, content, and method of any such legally-required notice and
          compliance with such laws and Yup shall indemnify the School for costs
          related to legally-required notifications. With respect to any
          Security Event which is not caused by the acts or omissions of Yup or
          its agents, Yup shall reasonably cooperate with School's investigation
          of the Security Event, as School requests, at School's reasonable
          expense. Yup shall be responsible for the timing, content, cost and
          method of notice and compliance with such laws as they relate to User
          accounts that are not associated with a School account.
        </p>
        <h2 className="text-lg mt-4 font-bold">Pricing</h2>
        <p>
          Yup reserves the right to determine final prevailing pricing. Please
          note the pricing information published on the website or app may not
          reflect the prevailing pricing.
        </p>
        <p>
          Yup, at its sole discretion, may make promotional offers with
          different features and different rates to any of our customers. These
          promotional offers, unless made to you, shall have no bearing
          whatsoever on your offer or contract. Yup may change the fees for our
          service or application, as we deem necessary for our business. We
          encourage you to check back at our website periodically if you are
          interested about how we charge for the service or application.
        </p>
        <h2 className="text-lg mt-4 font-bold">School Fees</h2>
        <p>
          Schools may work with Yup to purchase bulk licenses for their students
          to use. Yup reserves the right to charge fees for access to new Yup
          Services. In no event will you be charged for access to any new Yup
          Services, unless Yup obtains your prior agreement to pay such fees. If
          you do not consent to the payment of such fees, however, Yup may
          prohibit your access to that paid content or services. Details
          regarding the services you will receive in exchange for the payment of
          fees, as well as any payment terms and conditions that apply, will be
          disclosed to you prior to your agreement to pay such fees. All such
          terms will be deemed to be a part of (and are hereby incorporated by
          reference into) these Terms.
        </p>
        <h2 className="text-lg mt-4 font-bold">Consumer Plans</h2>
        <p>
          This section only applies to Yup plans purchased for individual
          subscriptions and not through schools.
        </p>
        <h3 className="mt-4 font-bold">Trial Policy</h3>
        <p>
          For all eligible consumer plans, Yup offers a seven-day trial period
          whereby you may access Yup's services, including use of the Materials
          and this website. This trial is only applicable for the first plan -
          eligible for the trial or not - that you purchase. You are entitled to
          a full refund if you email{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>{" "}
          and submit a fully completed Cancellation Request Form that is emailed
          to you before the end of the trial period. You will receive a
          confirmation email from Yup once Yup's processing of your cancellation
          request is complete. After the seven-day trial period ends, you will
          not be entitled to a refund.
        </p>
        <h3 className="mt-4 font-bold">Subscriptions and Auto-Renewals</h3>
        <p>
          Depending on the type of consumer plan you elect, Yup requires upfront
          payment of a subscription fee for use of Yup's services, including use
          of the Materials and this website, AND WILL AUTOMATICALLY CHARGE YOU
          THE APPLICABLE SUBSCRIPTION FEE, PLUS ANY APPLICABLE TAXES AND OTHER
          CHARGES (THE "<span className="font-bold">SUBSCRIPTION FEE</span>")
          FOR THE CONTINUED USE OF YUP'S SERVICES. HOWEVER, YOU MAY CANCEL THE
          AUTOMATIC CHARGE OF THE INITIAL SUBSCRIPTION FEE BY FOLLOWING THE
          PROCESS TO REQUEST A REFUND AS DESCRIBED ABOVE. IF YOU ELECT TO
          CONTINUE WITH THE SUBSCRIPTION, YOU WILL AUTOMATICALLY BE CHARGED THE
          SUBSCRIPTION FEE AT THE BEGINNING OF YOUR SUBSCRIPTION PERIOD AND EACH
          SUBSCRIPTION PERIOD THEREAFTER ON THE ANNIVERSARY OF THE COMMENCEMENT
          OF YOUR SUBSCRIPTION UNTIL YOUR PLAN ENDS OR YOU CANCEL YOUR
          SUBSCRIPTION AS SET FORTH BELOW. BY AGREEING TO THESE TERMS AND
          CONDITIONS AND ELECTING TO PURCHASE A SUBSCRIPTION, YOU ACKNOWLEDGE
          THAT YOUR SUBSCRIPTION HAS RECURRING PAYMENT FEATURES AND YOU ACCEPT
          RESPONSIBILITY FOR ALL RECURRING PAYMENT OBLIGATIONS PRIOR TO
          CANCELLATION, AS SET FORTH BELOW, OF YOUR SUBSCRIPTION.
        </p>
        <h3 className="mt-4 font-bold">Cancelling Subscriptions</h3>
        <p>
          You may cancel your subscription by contacting{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>{" "}
          before the end of the then-current subscription period. You will
          receive a confirmation email from Yup once Yup's processing of your
          cancellation request is complete, in which case such cancellation will
          be effective at the end of the then-current subscription period.
          Please note that you will not be entitled to a full or partial refund
          for the then-current subscription period, and you will be responsible
          for all Subscription Fees incurred for the then-current subscription
          period. If you cancel, your right to use Yup's services will continue
          until the end of your then-current subscription period and will then
          terminate without further charges.
        </p>
        <h3 className="mt-4 font-bold">Changing Subscriptions</h3>
        <p>
          If you elect one of the bulk subscription plans offered by Yup, such
          as the Family Plan, (collectively, "Bulk Subscription Plan"), you may
          remove individual plans covered under the Bulk Subscription Plan by
          following the cancellation procedures as set forth above. However, if
          you cancel individual plans under the Bulk Subscription Plan such that
          there is only one user left under the Bulk Subscription Plan, you will
          be subject to the individual Subscription Fees under the applicable
          subscription plan upon the next subscription period.
        </p>
        <h3 className="mt-4 font-bold">
          Plans purchased after August 20th, 2020
        </h3>
        <p>
          Beginning August 21st, 2020, if you would like to purchase a new plan
          to engage Yup's services, you must select either a one (1) month plan
          ("One Month Plan"), a three (3) month plan ("Three Month Plan"), or a
          twelve (12) month plan ("Twelve Month Plan"). The "Twelve Month Plan"
          is subject to a seven-day trial period as set forth under the Trial
          Policy section above. No plans are subject to automatic renewals at
          the specified end dates, and you must email Yup at{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>{" "}
          to renew your Plan. If you do not renew your Plan, then your access to
          the Yup services will end on the last day of your Plan.
        </p>
        <p className="mt-3">
          If you have purchased a prior, active subscription that is not
          reflective of Yup's current pricing model, you will be required to
          switch to Yup's new pricing model upon the expiration of your original
          plan. You may change your previously purchased subscription (or add an
          individual plan under a Bulk Subscription Plan) by emailing Yup at{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>
          . If you change your subscription, you will be charged the applicable
          fees for the Plan and you may be entitled to any applicable refunds.
        </p>
        <h2 className="text-lg mt-4 font-bold">Referrals</h2>
        <p>
          Yup consumer plan subscribers may refer new consumers to Yup and
          receive a credit towards their own Yup plans when the new consumer
          purchases and does not refund a Yup plan. To be eligible for referral
          credit, the new consumer must be a new Payer of no immediate relation
          to the original consumer purchasing their first Yup plan. Immediate
          relation to be defined as member of the same family or household
          (e.g., another child of the original Payer).
        </p>
        <h2 className="text-lg mt-4 font-bold">Contact Us</h2>
        <p>
          If you have any questions concerning Yup's Terms and Conditions,
          please contact Yup Customer Support at{" "}
          <a href="mailto:support@yup.com" className="text-blue">
            support@yup.com
          </a>
          .
        </p>
        <p className="mt-4">
          Thank you for reading our Terms. We hope you enjoy Yup!
        </p>
      </div>
    </Layout>
  )
}

export default Legal
